import "twin.macro"
import React from "react"
import Title from "../ui/Title"
import { StaticImage } from "gatsby-plugin-image"

const HRCollaborationSection = () => {
  return (
    <div tw="p-5 mx-auto max-w-7xl space-y-10 min-h-screen grid place-content-center">
      <div tw="mx-auto text-center">
        <Title>HR Collaboration</Title>
      </div>

      <div tw="grid md:grid-cols-2  justify-items-center gap-10">
        <div tw="space-y-5">
          <p tw="text-2xl font-black">
            Hire the right talent for different positions of your organisation
          </p>

          <div tw="mx-auto">
            <StaticImage
              layout="fullWidth"
              quality={100}
              placeholder="blurred"
              src="../../images/hr.png"
              alt="hr"
            />
          </div>
        </div>

        <div tw="grid place-content-center md:text-xl p-1 space-y-6">
          <p tw="">
            People are the most important asset of an organisation and to find
            the best talent in today's world, organisations need to be faster
            and more connected. Therefore, it becomes important to bring the
            right people for the organization from different backgrounds, skills
            and expertise.
          </p>
          <p>
            We not only help the organization's to find right talent but also
            advise on how to retain the existing ones. We are focused,
            optimised, reliable ,goal oriented and expert in hiring the right
            talent for different positions of your organization.
          </p>
        </div>
      </div>
    </div>
  )
}

export default HRCollaborationSection
