import "twin.macro"
import React from "react"
import Title from "../ui/Title"
import { BsFillBarChartFill } from "react-icons/bs"

const WhyCareerImportantData = [
  {
    title: "Career is a lot of your life",
    icon: BsFillBarChartFill,
  },
  {
    title: "It affects who you are at home",
    icon: BsFillBarChartFill,
  },
  {
    title: "It is a part of your identity",
    icon: BsFillBarChartFill,
  },
  {
    title: "It provides direction and purpose",
    icon: BsFillBarChartFill,
  },
  {
    title: "Work will define your social life",
    icon: BsFillBarChartFill,
  },
  {
    title: "Finding Your Passion is a Moral Obligation",
    icon: BsFillBarChartFill,
  },
]

const WhyCareerImportant = () => {
  return (
    <div tw="p-5 mx-auto max-w-7xl space-y-10 min-height[70vh] grid place-content-center">
      <div tw="mx-auto text-center">
        <Title>Why Career is Important</Title>
      </div>

      <div tw="grid md:grid-cols-2 justify-center items-center gap-5">
        {WhyCareerImportantData.map((item, index) => (
          <div tw="flex items-center space-x-2">
            <div tw="text-2xl text-yellow-300">
              <item.icon />
            </div>
            <p tw=" text-xl md:text-2xl "> {item.title}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default WhyCareerImportant
