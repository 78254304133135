import "twin.macro"
import React from "react"
import Title from "../ui/Title"
import { graphql, useStaticQuery } from "gatsby"
import BlogCard from "../ui/BlogCard"

const BlogSection = () => {
  const data: BlogDataProps = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 4
      ) {
        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          html
        }
      }
    }
  `)

  console.log(data)

  return (
    <div tw="p-5 mx-auto max-w-7xl space-y-10 min-h-screen grid place-content-center">
      <div tw="mx-auto text-center">
        <Title>Latest Blog</Title>
      </div>

      <div tw="grid md:grid-cols-3  justify-items-center gap-5">
        {data.allMarkdownRemark.nodes.map((card, index) => (
          <BlogCard
            name={card.frontmatter.title}
            content={card.excerpt}
            image={card.frontmatter.image}
            key={index}
            slug={card.fields.slug}
          />
        ))}
      </div>
    </div>
  )
}

export default BlogSection
