import "twin.macro"
import React, { FC } from "react"

import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

type props = { content: string; name: string; image: any }

const rotation = [-2, 1, -1, 2, -1, 1]

const ClientCard: FC<props> = ({ content, name, image }) => {
  const img = getImage(image) as IGatsbyImageData
  return (
    <div tw="w-full  max-w-lg h-full">
      <div tw="relative ml-0 mr-0 sm:mr-10 w-full h-full">
        <span tw="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-yellow-300 rounded-lg"></span>
        <div tw="relative h-full p-5 bg-white border-2 border-yellow-300 rounded-lg">
          <div tw="flex items-center -mt-1">
            <GatsbyImage
              tw="rounded-full h-10 w-10"
              image={img}
              alt="profile"
            />
            <h3 tw="my-2 ml-3 text-lg font-medium  text-gray-900">{name},</h3>
            {/* <p tw=" text-gray-900">{profile}</p> */}
          </div>

          <p tw="mb-2 text-gray-600">{content}</p>
        </div>
      </div>
    </div>
  )
}

export default ClientCard
