import "twin.macro"
import React from "react"
import Title from "../ui/Title"
import ClientCard from "../ui/ClientCard"
import { graphql, useStaticQuery } from "gatsby"

interface HeaderData {
  allTestimonialJson: {
    nodes: {
      name: string
      content: string
      image: {
        childImageSharp: {
          gatsbyImageData: any
        }
      }
    }[]
  }
}

const OurClientSection = () => {
  const OurClientData: HeaderData = useStaticQuery(graphql`
    query MyQuery {
      allTestimonialJson {
        nodes {
          name
          content
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  return (
    <div tw="p-5 mx-auto max-w-7xl space-y-10 min-h-screen grid place-content-center">
      <div tw="mx-auto text-center space-y-2">
        <Title>Testimonial</Title>

        <p tw="text-xl mx-auto max-w-lg">
          Don't just take our word for it, read from our extensive list of case
          studies and customer testimonials.
        </p>
      </div>

      <div tw="grid md:grid-cols-3 gap-5">
        {OurClientData.allTestimonialJson.nodes.map((item, index) => (
          <ClientCard {...item} key={index} />
        ))}
      </div>
    </div>
  )
}

export default OurClientSection
