import * as React from "react"
import { Link, graphql, PageProps } from "gatsby"
import HeroSection from "../components/home/HeroSection"
import OurServiceSection from "../components/home/OurServiceSection"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import OurClientSection from "../components/home/OurClientSection"
import WhyCareerImportant from "../components/home/WhyCareerImportant"
import HRCollaborationSection from "../components/home/HRCollaborationSection"
import BlogSection from "../components/home/BlogSection"

const BlogIndex: React.FC<PageProps> = ({ data }) => {
  return (
    <Layout>
      <Seo title="Home" />
      <HeroSection />
      <OurServiceSection />
      <OurClientSection />
      <WhyCareerImportant />
      <HRCollaborationSection />
      <BlogSection />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
